import service from '@/utils/request'; // @Tags priceManange
// @Summary 获取商品价格列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.priceManange true "获取商品价格列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /priceManange/index [post]

export var getPriceControl = function getPriceControl(data) {
  return service({
    url: "/price_control/goods_price",
    method: 'post',
    data: data
  });
}; // @Tags priceManange
// @Summary 修改商品管理价格
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.priceManange true "更新priceManange"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /price_control/goods_price [put]

export var updatePriceControl = function updatePriceControl(data) {
  return service({
    url: "/price_control/goods_price",
    method: 'put',
    data: data
  });
}; // @Tags priceManange
// @Summary 导出价格管理
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.priceManange true "导出价格管理"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /price_control/export_goods_price [get]

export var exportPriceControl = function exportPriceControl(params) {
  return service({
    url: "/price_control/export_goods_price",
    method: 'get',
    params: params
  });
};