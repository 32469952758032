var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-Resource-content" },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            "span-method": _vm.objectSpanMethod,
            border: "",
            stripe: "",
            "header-cell-style": { textAlign: "center" },
            "tooltip-effect": "dark"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "SPU ID", prop: "id", width: "100px" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "SPU内部名称",
              prop: "internal_name",
              width: "150px"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "SPU名称", prop: "name", width: "150px" }
          }),
          _c("el-table-column", {
            attrs: { label: "原价（元）", prop: "sale_price", width: "90px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatSpuPrice")(scope.row.sale_price)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "基础佣金（元）",
              prop: "base_commission",
              width: "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatSpuPrice")(scope.row.base_commission)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "SKU ID", prop: "spec_id", width: "100px" }
          }),
          _c("el-table-column", {
            attrs: { label: "SKU 名称", prop: "spec_name", width: "150px" }
          }),
          _c("el-table-column", {
            attrs: { label: "关联供应商SKU ID", prop: "sku_id", width: "100px" }
          }),
          _c("el-table-column", {
            attrs: { label: "供应商SKU名称", prop: "sku_name", width: "150px" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "上游成本价（元）",
              prop: "sc_cost_price",
              width: "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatSkuPrice")(scope.row.sc_cost_price)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "上游价格变动（元）",
              prop: "update_float_price",
              width: "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.update_float_price > 0
                      ? _c("p", [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-top",
                              staticStyle: { color: "#f56c6c" }
                            },
                            [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm._f("formatSkuPrice")(
                                      scope.row.update_float_price
                                    )
                                  )
                              )
                            ]
                          )
                        ])
                      : scope.row.update_float_price < 0
                      ? _c("p", [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-bottom",
                              staticStyle: { color: "#67c23a" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatSkuPrice")(
                                    scope.row.update_float_price
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatSkuPrice")(
                                scope.row.update_float_price
                              )
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间", prop: "update_time", width: "150px" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.changePrice(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("修改价格")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改价格  （新系统）",
            visible: _vm.dialogVisible,
            width: "88%",
            "before-close": _vm.beforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "spu-info" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.priceData,
                    "label-width": "90px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "SPU ID:" }
                    },
                    [_vm._v(_vm._s(_vm.priceData.id))]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "SPU 名称:" }
                    },
                    [_vm._v(_vm._s(_vm.priceData.name))]
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { padding: "0" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                "label-width": "110px",
                                label: "原价（元）:"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "9" },
                                on: {
                                  input: function($event) {
                                    return _vm.specifiSpu($event, "sale_price")
                                  }
                                },
                                model: {
                                  value: _vm.priceData.sale_price,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.priceData,
                                      "sale_price",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "priceData.sale_price"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "10px" },
                          attrs: { span: 7 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                "label-width": "130px",
                                label: "基础佣金（元）:"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "90%" },
                                attrs: { maxlength: "9" },
                                on: {
                                  input: function($event) {
                                    return _vm.specifiSpu(
                                      $event,
                                      "base_commission"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.priceData.base_commission,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.priceData,
                                      "base_commission",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "priceData.base_commission"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                "label-width": "130px",
                                label: "我平台佣金（元）:"
                              }
                            },
                            _vm._l(_vm.priceData.spec_infos, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index },
                                _vm._l(item.sku_infos, function(ditem, dindex) {
                                  return _c("div", { key: dindex }, [
                                    _vm._v(" " + _vm._s(ditem.sc_name) + "："),
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            _vm.formatSpuPrice(ditem) > 0
                                              ? "#409EFF"
                                              : "#FF0033"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formatSpuPrice(ditem))
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.priceData.spec_infos, function(sitem, sindex) {
            return _c(
              "div",
              { key: sindex, staticStyle: { "margin-top": "30px" } },
              [
                _c("p", { staticClass: "sku-title" }, [
                  _vm._v("SKU ID: " + _vm._s(sitem.id))
                ]),
                _c("p", { staticClass: "sku-title" }, [
                  _vm._v("SKU名称: " + _vm._s(sitem.name))
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: sitem.sku_infos }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序", type: "index", "min-width": "15%" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sc_name",
                        label: "供应商",
                        "min-width": "20%"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "id",
                        label: "供应商SKU ID",
                        "min-width": "15%"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "关联供应商SKU 名称",
                        "min-width": "30%"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status",
                        label: "状态",
                        "min-width": "20%"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.status === -1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("下架")]
                                    )
                                  : _vm._e(),
                                scope.row.status === 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("上架")]
                                    )
                                  : _vm._e(),
                                scope.row.status === 2
                                  ? _c("el-tag", [_vm._v("售罄")])
                                  : _vm._e(),
                                scope.row.status === 3
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("脚本下架")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sc_cost_price",
                        label: "上游成本价",
                        "min-width": "20%"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "info", effect: "dark" } },
                                  [_vm._v(_vm._s(scope.row.sc_cost_price))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "face_price",
                        label: "售价",
                        "min-width": "20%"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "info", effect: "dark" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatSkuPrice")(
                                          scope.row.face_price
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sale_price",
                        label: "建议零售价",
                        "min-width": "20%"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "info", effect: "dark" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatSkuPrice")(
                                          scope.row.sale_price
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "level",
                        label: "排序",
                        "min-width": "10%"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "90%" },
                                  attrs: {
                                    maxlength: "9",
                                    onKeyUp:
                                      "this.value=this.value.replace(/\\D|^0/g,'')"
                                  },
                                  model: {
                                    value: scope.row.level,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "level",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row.level"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c("p", { staticClass: "sku-title" }, [_vm._v("优惠券")]),
              _c(
                "el-table",
                { attrs: { data: _vm.priceData.coupon_infos } },
                [
                  _c("el-table-column", {
                    attrs: { label: "序", type: "index", width: "50" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "优惠券ID", width: "80" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "类型", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.type === 1 ? "单品券" : "平台券"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "优惠券名称", width: "130" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "discount_money",
                      label: "优惠面额（元）",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                disabled: !(
                                  scope.row.type === 1 &&
                                  scope.row.discount_type === 1
                                ),
                                maxlength: "9"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "discount_money"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.discount_money,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "discount_money",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.discount_money"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "discount_ratio",
                      label: "优惠折扣",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.type === 1 &&
                            scope.row.discount_type === 4
                              ? [
                                  _c("el-input", {
                                    staticStyle: { width: "90%" },
                                    attrs: { maxlength: "9" },
                                    on: {
                                      input: function($event) {
                                        return _vm.specifiPrice(
                                          $event,
                                          scope.$index,
                                          "discount_ratio"
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.discount_ratio,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "discount_ratio",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.discount_ratio"
                                    }
                                  })
                                ]
                              : [_vm._v(" -- ")]
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customer_income",
                      label: "佣金（元）",
                      width: "110"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                disabled: scope.row.type !== 1,
                                maxlength: "9"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.specifiPrice(
                                    $event,
                                    scope.$index,
                                    "customer_income"
                                  )
                                }
                              },
                              model: {
                                value: scope.row.customer_income,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "customer_income",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.customer_income"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "优惠文案", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("preference")(scope.row)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "默认优惠券", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-switch", {
                              on: {
                                change: function($event) {
                                  return _vm.setCoupons(
                                    $event,
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              },
                              model: {
                                value: scope.row.is_default,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "is_default", $$v)
                                },
                                expression: "scope.row.is_default"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "优惠档次", width: "210" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.type === 1
                              ? [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: scope.row.discount_level,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "discount_level",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.discount_level"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            margin: "10px 25px 0 0"
                                          },
                                          attrs: { label: 3 }
                                        },
                                        [_vm._v("高")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            margin: "10px 25px 0 0"
                                          },
                                          attrs: { label: 2 }
                                        },
                                        [_vm._v("中")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            margin: "10px 25px 0 0"
                                          },
                                          attrs: { label: 1 }
                                        },
                                        [_vm._v("低")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            margin: "10px 25px 0 0"
                                          },
                                          attrs: { label: 0 }
                                        },
                                        [_vm._v("不选择")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : [_vm._v(" -- ")]
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "internal_name",
                      label: "券名称",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "券图片", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: scope.row.resource_cover,
                                fit: "contain"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "我平台佣金", width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(_vm.skuList, function(kitem, kindex) {
                            return _c("div", { key: kindex }, [
                              _c("p", [
                                _vm._v(" " + _vm._s(kitem.sc_name) + "： "),
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        _vm.formatCouponPrice(
                                          kitem,
                                          scope.row
                                        ) > 0
                                          ? "#409EFF"
                                          : "#FF0033"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCouponPrice(kitem, scope.row)
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div"),
          _c(
            "div",
            { staticStyle: { color: "#ff0033", "margin-top": "20px" } },
            [
              _c("p", { staticStyle: { padding: "10px 0" } }, [
                _vm._v(
                  " PS:修改价格请注意同时修改原价/基础佣金/优惠券面额/佣金 "
                )
              ]),
              _c("p", [
                _vm._v(
                  "我平台佣金=商品原价—（基础佣金 或（优惠面额+佣金）) -上游成本价"
                )
              ])
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { display: "block", margin: "auto" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onSave }
                },
                [_vm._v("确认修改")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }